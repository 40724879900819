body {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: Georgia, 'Times New Roman', Times, serif;
  /* background-position: center;
  display: block;
  position: inherit;
  background-image: url(./assets/img/planet-eclipse.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */

  /* background: url('./assets/img/planet-eclipse.jpg') no-repeat center center fixed; */
  /* background: url('./assets/img/space-Bg.jpg') no-repeat center center fixed; */
  background: url('./assets/img/planet-eclipse.jpg') no-repeat center center fixed;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin: 0;
  height: 100vh;
  width: 100%;
  background-color: black;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
h1, h2, h3, p, label {
  text-shadow: -1px 1px 2px rgba(0, 0, 0, 1)

}

.centerTxt{
  text-align: center;
}
